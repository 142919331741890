.our-services {
    margin: 50px 0;
    background-color: beige;
    height: 30rem;
  }
.text-container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    font-size: 2rem;
}
  .service-items {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  
  .service-item {
    background-color: white;
    padding: 50px;
    width: 20%;
    border-radius: 30px;
    margin-left: 50px;
  }
  
  .service-item h3 {
    margin-top: 0;
  }
  @media screen and (max-width: 768px) {
    .service-items {
      flex-direction: column;
    }
    .service-item {
      margin-bottom: 20px;
    }
  }