.footer{
    background-color: #D2C4EA;
    width: 100%;
}
.footer-top{
    display: flex;
    flex-direction: row;
   
}
.footer-top-left{
    width: 50%;
}
.footer-top-text{
    font-size: 1.5rem;
    margin-top: 10px;
    margin-left: 20px ;
}
.footer-top-right{
    width: 50%;
    margin-left: 50%;
    
}
.footer-input{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.footer-icons{
    margin-top: 20px;
    
}
.footer-icons .icon{
    margin-left: 20px;
}
.footer-bottom{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
}
.footer-bottom-top  {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.footer-bottom-top p{
    margin-right: 3rem;
}
.subscribe-button {
    width: 160px;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
    margin: 20px;
    height: 55px;
    text-align: center;
    border: none;
    background-size: 300% 100%;
    border-radius: 50px;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }
  
  .subscribe-button:hover {
    background-position: 100% 0;
    
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
  }
  
  .subscribe-button:focus {
    outline: none;
  }
  
  .subscribe-button{
    background-image: linear-gradient(
        to right,
        #6253e1,
        #852d91,
        #a3a1ff,
        #f24645
      );
    box-shadow: 0 4px 15px 0 rgba(126, 52, 161, 0.75);
  }
  
  
  .input {
    width: 280px;
    height: 55px;
    border: none;
    outline: none;
    caret-color: rgb(255, 81, 0);
    background-color: rgb(255, 255, 255);
    border-radius: 30px;
    padding-left: 15px;
    letter-spacing: 0.8px;
    color: rgb(19, 19, 19);
    font-size: 13.4px;
  }
  @media (max-width: 1243px) {
    .footer-top{
        display: flex;
        flex-direction: column;
    }
    .footer-top-left{
        width: 100%;
    }
    .footer-top-right{
        width: 100%;
        margin-left: 0;
    }
    .footer-bottom-top{
        display: flex;
        flex-direction: row;
        font-size: 13px;
    }
    .footer-bottom-top p{
        margin-right: 0;
    }
    .footer-input{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .footer-top-text{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .footer-icons{
        display: flex;
        justify-content: center;
        align-items: center;
    }
  }