form {
    display: flex;
    flex-direction: column;
    width: 500px;
    margin: 0 auto;
    margin-top: 100px;
  }
  
  label {
    margin-top: 10px;
    display: block;
  }
  
  input,
  select,
  textarea {
    padding: 5px;
    margin-top: 5px;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  input[type="file"] {
    padding: 5px 15px;
  }
  
  input[type="submit"] {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  input[type="submit"]:hover {
    background-color: #45a049;
  }
    .uploadcare--widget__button_type_open {
      background-color: rgb(147 51 234)  !important;
    }