.tour{
    /* background: whitesmoke; */
    height: 100vh;
    margin: 0;
  }
  
  h2{
    margin: 0;
    color: black;
  }

  .tour__head{
    padding-top: 15vh;
    text-align: center;
    margin-bottom: 50px;
  }

.tour{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
  
  .image-container{
    height: 26.190rem;
    position: relative;
    width: 78%;
    display: block;
    margin: 0 auto;
    margin-left: 12%;
    overflow: hidden;
    
  border: 2px solid black;
  border-radius: 10px;
  }
  
  canvas{
    width: 100%;
  }

  .uploadcare--widget__button_type_open {
    background-color: rgb(168 85 247) !important; /* Equivalent to bg-purple-500 in Tailwind */
  }
  
  .uploadcare--widget__button_type_open:hover {
    opacity: 0.5 !important;
  }